<template>
  <v-dialog v-model="dialog" max-width="530px">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="fetchSubServices">
        <v-icon large>mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("add_pack") }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row class="py-0">
          <v-col cols="12" md="4" class="py-0">
            <label>{{ $t("packs.description") }}</label>
            <v-text-field
              dense
              outlined
              hide-details
              v-model="pack.description"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <label>{{ $t("packs.price") }}</label>
            <v-text-field
              dense
              type="number"
              hide-details
              outlined
              v-model="pack.price"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="py-0">
            <label>{{ $t("packs.real_price") }}</label>
            <v-text-field
              dense
              hide-details
              disabled
              :value="real_price()"
              outlined
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-simple-table background-color="transparent" class="my-0 ml-2">
        <thead v-if="!$store.getters['auth/isStudio']">
          <tr>
            <td style="color: var(--v-primary-base)">
              {{ $tc("studio", 1) }}
            </td>
          </tr>
        </thead>
        <thead>
          <tr>
            <th style="color: var(--v-primary-base) ">
              {{ $t("packs.sub_artist_type") }}
            </th>
            <th style="color: var(--v-primary-base)">
              {{ $tc("quantity", 1) }}
            </th>
            <th style="color: var(--v-primary-base)">
              {{ $tc("price", 1) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(sub_artist_type, i) in pack.sub_artist_type" :key="i">
            <td class="pt-0">
              <v-autocomplete
                :items="sub_artist_types"
                outlined
                dense
                data-vv-validate-on="blur"
                v-validate="'required'"
                hide-details
                size="15"
                style="padding-top: 0 !important"
                v-model="sub_artist_type.sub_artist_type_id"
                :item-text="(x) => `${x.reference} - ${x.description}`"
                item-value="id"
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                dense
                outlined
                type="number"
                hide-details
                v-model="sub_artist_type.quantity"
                style="padding-top: 0 !important"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                dense
                outlined
                hide-details
                disabled
                :value="price(sub_artist_type)"
                style="padding-top: 0 !important"
              ></v-text-field>
            </td>
            <td>
              <v-btn icon @click="del(i)" style="margin-left: -20px">
                <v-icon size="18">$delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <v-btn x-small fab outlined @click="add">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="dialog = false"
          style="height: 25px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          style="color: #363533; height: 25px; padding-top: 10px !important"
          elevation="0"
          @click="save"
        >
          <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "NewPack",
  components: {},
  data() {
    return {
      pack: {
        description: "",
        sub_artist_type: [{}],
        price: null,
      },
      sub_artist_types: [],
      dialog: false,
      subservicePack: [],
    };
  },
  methods: {
    ...mapActions("subservices", ["getSubServices"]),

    fetchSubServices() {
      console.log("FETCH");
      this.loading = true;
      this.getSubServices({
        filters: {},
        pagination: { itemsPerPage: -1 },
        service_id: "all",
      }).then((subservices) => {
        this.sub_artist_types = subservices.data;
        console.log(this.sub_artist_types);
      });
    },

    real_price() {
      console.log("REAL PRICE");
      let price = 0;

      //GET TOTAL PRICE OF AL SUBSERVICES ADDED
      this.pack.sub_artist_type.forEach((sub) => {
        let sat = this.sub_artist_types.find(
          (sat) => sat.id == sub.sub_artist_type_id
        );
        console.log("SAT", sat);
        console.log("SUB", sub);
        if (sat && sub.quantity) {
          price += sub.quantity * sat.price;
        } else {
          price = 0;
        }
      });
      return price;
    },

    add() {
      this.pack.sub_artist_type.push({});
    },
    del(index) {
      this.$confirm("¿Seguro que quieres eliminar?", "", "").then(() =>
        this.pack.sub_artist_type.splice(index, 1)
      );
    },
    price(sub_artist_type) {
      let sat = this.sub_artist_types.find(
        (sat) => sat.id == sub_artist_type.sub_artist_type_id
      );
      if (sat && sub_artist_type.quantity) {
        return this.$n(sat.price * sub_artist_type.quantity, "currency");
      }
    },

    ...mapActions("packs", ["addPack"]),
    save() {
      console.log("ADD PACK");
      this.loading = true;
      this.addPack(this.pack).then((response) => {
        console.log(this.pack);
        if (response.success) {
          this.$alert(this.$t("save_ok"));
          this.dialog = false;
          this.$emit("update");
        }
      });
    },
  },
};
</script>

<style scoped lang="sass">

label
//line-height: 1
  height: 25px
  display: block

.v-data-table  .v-data-table__wrapper table  tbody  tr td
  text-align: left !important
.v-data-table.theme--dark tr th
  color: var(--v-primary-base) !important
  font-size: 12px !important
</style>
